import { SubscriptionType } from '4k-web/models/subscription';
import Service, { Registry, inject as service } from '@ember/service';

export default class Payments extends Service {
  @service declare store: Registry['store'];

  async buySubscription(subscriptionType: SubscriptionType) {
    const payment = this.store.createRecord('payment', { subscriptionType });

    const result = await payment.save();
    window.location.href = result.paymentLink;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    payments: Payments;
  }
}
