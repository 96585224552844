import Component from '@glimmer/component';
import { inject as service, Registry } from '@ember/service';
import { Maybe } from 'utils';
import type { IconName } from '@fortawesome/fontawesome-svg-core';

export interface MainMainMenuItemSignature {
  Element: HTMLElement;
  Args: {
    label: string;
    icon: IconName;
    disabled?: Maybe<boolean>;
    routeName?: string;
    action?: () => void;
  };
}

export default class MainMainMenuItem extends Component<MainMainMenuItemSignature> {
  @service declare router: Registry['router'];

  get isActive(): boolean {
    if (this.args.routeName) {
      return this.router.currentRouteName.includes(this.args.routeName);
    }

    return false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MainMenu::Item': typeof MainMainMenuItem;
  }
}
