import { action } from '@ember/object';
import { TaskForAsyncTaskFunction } from 'ember-concurrency';
import Component from '@glimmer/component';
import type { BufferedChangeset } from 'validated-changeset';

interface ContactDataFormSignature {
  Element: HTMLFormElement;
  Args: {
    withoutButton?: boolean;
    changeset: BufferedChangeset;
    isOnboardingForm?: boolean;
    onSubmit?: TaskForAsyncTaskFunction<
      unknown,
      (changeset: BufferedChangeset, e: Event) => Promise<void>
    >;
  };
}

export default class ContactDataForm extends Component<ContactDataFormSignature> {
  @action
  async updateFirstName({ target }: Event) {
    // @ts-expect-error Wrong typings
    this.args.changeset.firstName = target.value;
    await this.args.changeset.validate();
  }

  @action
  async updateLastName({ target }: Event) {
    // @ts-expect-error Wrong typings
    this.args.changeset.lastName = target.value;
    await this.args.changeset.validate();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Profile::ContactDataForm': typeof ContactDataForm;
  }
}
