import Component from '@glimmer/component';
import type { IconName } from '@fortawesome/fontawesome-svg-core';

export interface UiHeaderSignature {
  Element: HTMLElement;
  Args: {
    level?: number;
    icon?: IconName;
    text: string;
  };
  Blocks: {
    text: [];
    subtitle: [];
  };
}

export default class UiHeader extends Component<UiHeaderSignature> {
  get level(): number {
    return this.args.level ?? 1;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Header': typeof UiHeader;
  }
}
