import Component from '@glimmer/component';
import BookingsList from '4k-web/components/bookings/list';
import {
  eachDayOfInterval,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isWeekend,
  startOfISOWeek,
  startOfMonth,
  toDate,
} from 'date-fns';

type PowerCalendarDay = {
  id: string;
  number: number;
  date: Date;
  isBooked: boolean;
  isDisabled: boolean;
  isFullyBooked: boolean;
  isCurrentMonth: boolean;
  isToday: boolean;
  isSelected: boolean;
};

type CalendarWeek = { id: string; days: PowerCalendarDay[] };

export interface CalendarDaySignature {
  Element: HTMLDivElement;
  Args: {
    bookedDates: Date[];
    cancelBookingTask: BookingsList['cancelBookingTask'];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    calendar: any; // PowerCalendar;
    maxDate: Date;
    maxLength: number;
    unavailableDates: string[];
  };
}

export default class CalendarDay extends Component<CalendarDaySignature> {
  get weekdaysNames(): string[] {
    return ['pon.', 'wt.', 'śr.', 'czw.', 'pt.', 'sob.', 'nd.'];
  }

  get weeks(): CalendarWeek[] {
    const weeks = [];

    for (let i = 0; i < this.days.length; i += 7) {
      const daysOfWeek = this.days.slice(i, i + 7);
      const firstDayOfWeek = daysOfWeek[0];

      weeks.push({
        id: `week-of-${firstDayOfWeek?.id}`,
        days: daysOfWeek,
      });
    }

    return weeks;
  }

  private get currentCenter() {
    return this.args.calendar.center;
  }

  private get days(): PowerCalendarDay[] {
    const start = startOfISOWeek(startOfMonth(this.currentCenter));
    const end = endOfISOWeek(endOfMonth(this.currentCenter));
    const eachDays = eachDayOfInterval({ start, end });
    const formattedDays = eachDays.map((day) => this.buildDay(day));

    return formattedDays;
  }

  private buildDay(date: Date) {
    const id = format(date, 'yyyy-MM-dd');

    return {
      id,
      number: date.getDate(),
      date,
      isBooked: this.dayIsBooked(date),
      isDisabled: this.dayIsDisabled(date),
      isCurrentMonth: date.getMonth() === this.currentCenter.getMonth(),
      isFullyBooked: this.dayIsFullyBooked(date),
      isToday: isSameDay(date, new Date()),
      isSelected: this.dayIsSelected(date),
    };
  }

  private isBankHoliday(date: Date) {
    const bankHolidays = [
      '2023-01-01',
      '2023-01-06',
      '2023-04-09',
      '2023-04-10',
      '2023-05-01',
      '2023-05-03',
      '2023-05-28',
      '2023-06-08',
      '2023-08-15',
      '2023-11-01',
      '2023-11-11',
      '2023-12-25',
      '2023-12-26',
    ];

    return bankHolidays.includes(format(date, 'yyyy-MM-dd'));
  }

  private dayIsBooked(date: Date) {
    return this.args.bookedDates.some((d) => isSameDay(date, d));
  }

  private dayIsDisabled(date: Date) {
    const numSelected = this.args.calendar.selected?.length || 0;
    const maxLength = this.args.maxLength;

    const currentDate = toDate(new Date().setHours(0, 0, 0, 0));

    if (isBefore(date, currentDate)) {
      return true;
    }

    if (isAfter(date, endOfDay(this.args.maxDate))) {
      return true;
    }

    if (isWeekend(date)) {
      return true;
    }

    if (this.isBankHoliday(date)) {
      return true;
    }

    if (this.dayIsBooked(date)) {
      return false;
    }

    if (this.dayIsFullyBooked(date)) {
      return true;
    }

    if (numSelected >= maxLength && !this.dayIsSelected(date)) {
      return true;
    }

    return false;
  }

  private dayIsFullyBooked(date: Date) {
    const shortDate = format(date, 'yyyy-MM-dd');
    return this.args.unavailableDates.includes(shortDate);
  }

  private dayIsSelected(date: Date) {
    const selected = this.args.calendar.selected || [];
    return selected.some((d: Date) => isSameDay(date, d));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calendar::Day': typeof CalendarDay;
    'calendar/day': typeof CalendarDay;
  }
}
