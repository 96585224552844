import { Registry, inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { OAuthProviders } from '4k-web/services/auth';
import type UiButton from '4k-web/components/ui/button';

export interface LoginOauthButtonSignature {
  Element: UiButton;
  Args: {
    provider: OAuthProviders;
  };
}

export default class LoginOauthButton extends Component<LoginOauthButtonSignature> {
  @service declare auth: Registry['auth'];
  @service declare router: Registry['router'];

  get providerNameLowercased(): OAuthProviders {
    return this.args.provider.toLowerCase() as OAuthProviders;
  }

  get bgClass(): string {
    return `bg-${this.providerNameLowercased} hover:bg-${this.providerNameLowercased}-dark`;
  }

  loginWithOAuthTask = task(async (provider: OAuthProviders) => {
    await this.auth.signInWithOAuth(provider);
    this.router.transitionTo('index');
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Login::OauthButton': typeof LoginOauthButton;
  }
}
