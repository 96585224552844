import { Registry, inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export interface LoginNewPasswordSignature {
  Element: HTMLFormElement;
  Args: object;
}

export default class LoginNewPassword extends Component<LoginNewPasswordSignature> {
  @service declare auth: Registry['auth'];
  @service declare flashMessages: Registry['flash-messages'];
  @service declare router: Registry['router'];

  @tracked password = '';

  get isSaveDisabled(): boolean {
    return !this.password || this.updatePasswordTask.isRunning;
  }

  updatePasswordTask = task(async (e: Event) => {
    e.preventDefault();

    try {
      await this.auth.updateEmailOrPassword(undefined, this.password);
      this.flashMessages.success('🎉 Hasło zostało pomyślnie zmienione!');
      this.router.transitionTo('bookings');
    } catch (e) {
      this.flashMessages.danger('💥 Zmiana hasła nie powiodła się.');
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Login::NewPassword': typeof LoginNewPassword;
  }
}
