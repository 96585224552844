import { modifier } from 'ember-modifier';

export default modifier((element, [targetName]: [string, () => void]) => {
  element.addEventListener('click', handler);

  function handler() {
    document.querySelector(`[data-collapse-id="${targetName}"]`)?.classList.toggle('hidden');
  }

  return () => {
    element.removeEventListener('click', handler);
  };
});
