import Component from '@glimmer/component';
import { inject as service, Registry } from '@ember/service';
import { BufferedChangeset } from 'validated-changeset';
import { task } from 'ember-concurrency';
import ContactDataValidations from '4k-web/validations/contact-data';

interface OnboardingFormSignature {
  Element: HTMLFormElement;
  Args: object;
}

export default class OnboardingForm extends Component<OnboardingFormSignature> {
  @service declare auth: Registry['auth'];
  @service declare flashMessages: Registry['flash-messages'];
  @service declare maintenance: Registry['maintenance'];
  @service declare router: Registry['router'];

  ContactDataValidations = ContactDataValidations;

  updateContactDataTask = task(async (changeset: BufferedChangeset, e: Event) => {
    e.preventDefault();
    await changeset.save();
    this.router.transitionTo('bookings');
    this.flashMessages.success('🎉 Zaktualizowano dane kontaktowe!');
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    OnboardingForm: typeof OnboardingForm;
  }
}
