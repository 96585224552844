import {
  validateLength,
  validatePresence,
  // @ts-expect-error seems like something is wrong with detection of the exported members
} from 'ember-changeset-validations/validators';

export default {
  taxId: [validateLength({ min: 10, max: 10, allowBlank: true })],
  phone: validateLength({ min: 9 }),
  firstName: validatePresence(true),
  lastName: validatePresence(true),
};
