// import UserModel from '4k-web/models/user';
import UserModel from '4k-web/models/user';
import Restricted from '4k-web/routes/abstract-restricted';
import { inject as service, Registry } from '@ember/service';

export default class Onboarding extends Restricted {
  @service declare auth: Registry['auth'];
  @service declare router: Registry['router'];

  async beforeModel() {
    await super.beforeModel();

    if (!(this.auth.localUser as UserModel).isOnboarded) {
      this.router.transitionTo('onboarding');
    } else {
      this.router.transitionTo('index');
    }
  }
}
