import { inject as service } from '@ember/service';
import type { Registry } from '@ember/service';
import ApplicationAdapter from './application';

export default class PaymentAdapter extends ApplicationAdapter {
  @service declare store: Registry['store'];

  async buySubscription() {
    const url = this.buildURL('payment');

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth.token}`,
      },
      body: JSON.stringify({}),
    });

    const responseJSON = await response.json();

    if (!response.ok) {
      throw new Error('Failed to buy subscription.');
    }

    this.store.pushPayload(responseJSON);
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    payment: PaymentAdapter;
  }
}
