import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { inject as service, Registry } from '@ember/service';
import config from '4k-web/config/environment';
import { buildAdapterOptionsParams } from '4k-web/utils/build-adapter-options-params';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type ModelRegistry from 'ember-data/types/registries/model';

export default class ApplicationAdapter extends JSONAPIAdapter {
  @service declare auth: Registry['auth'];

  host = config.apiHost;

  get headers() {
    return {
      Authorization: `Bearer ${this.auth.token}`,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  urlForCreateRecord(modelName: keyof ModelRegistry, snapshot: any) {
    const url = super.urlForCreateRecord(modelName, snapshot);
    return buildAdapterOptionsParams(snapshot, url);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  urlForFindRecord(id: string, modelName: keyof ModelRegistry, snapshot: any) {
    const url = super.urlForFindRecord(id, modelName, snapshot);
    return buildAdapterOptionsParams(snapshot, url);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  urlForUpdateRecord(id: string, modelName: keyof ModelRegistry, snapshot: any) {
    const url = super.urlForUpdateRecord(id, modelName, snapshot);
    return buildAdapterOptionsParams(snapshot, url);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  urlForDeleteRecord(id: string, modelName: keyof ModelRegistry, snapshot: any) {
    const url = super.urlForDeleteRecord(id, modelName, snapshot);
    return buildAdapterOptionsParams(snapshot, url);
  }
}
