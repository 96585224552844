import { Task } from 'ember-concurrency';
import templateOnlyComponent from '@ember/component/template-only';
import type { BufferedChangeset } from 'validated-changeset';

interface RemoveAccountFormSignature {
  Element: HTMLFormElement;
  Args: {
    onSubmit: Task<BufferedChangeset, [void]>;
  };
}

const RemoveAccountForm = templateOnlyComponent<RemoveAccountFormSignature>();

export default RemoveAccountForm;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Profile::RemoveAccountForm': typeof RemoveAccountForm;
  }
}
