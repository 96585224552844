import { assert } from '@ember/debug';
import { get } from '@ember/object';

const paramsArray: string[] = [];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function buildAdapterOptionsParams(snapshot: any, url: string) {
  if (!snapshot) return url;

  // eslint-disable-next-line ember/no-get
  const query = get(snapshot, 'adapterOptions.query');

  if (!query || !Object.keys(query).length) return url;
  return `${url}?${encodeQueryParams(query)}`;
}

function addToQueryString({ key, value }: { key: string; value: string }) {
  paramsArray[paramsArray.length] = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function encodeQueryParams(query: any) {
  paramsArray.length = 0;
  assert('query parameters must be an object (you passed an array)', !Array.isArray(query));

  for (const mainKey in query) {
    addToQueryString({ key: mainKey, value: query[mainKey] });
  }

  return paramsArray.join('&').replace(/%20/g, '+');
}
