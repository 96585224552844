import { TaskForAsyncTaskFunction } from 'ember-concurrency';
import type { BufferedChangeset } from 'validated-changeset';
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface ProfilePasswordFormSignature {
  Element: HTMLFormElement;
  Args: {
    changeset: BufferedChangeset;
    onSubmit: TaskForAsyncTaskFunction<
      unknown,
      (changeset: BufferedChangeset, e: Event) => Promise<void>
    >;
  };
}

export default class ProfilePasswordForm extends Component<ProfilePasswordFormSignature> {
  @action
  async updatePassword({ target }: Event) {
    // @ts-expect-error Wrong typings
    this.args.changeset.password = target.value;
    await this.args.changeset.validate();
  }

  @action
  async updatePasswordConfirmation({ target }: Event) {
    // @ts-expect-error Wrong typings
    this.args.changeset.passwordConfirmation = target.value;
    await this.args.changeset.validate();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Profile::PasswordForm': typeof ProfilePasswordForm;
  }
}
