import Service, { inject as service, Registry } from '@ember/service';
import { EmberOwner } from '4k-web/types/utils';
import { tracked } from '@glimmer/tracking';
import config from '4k-web/config/environment';
import UserModel from '4k-web/models/user';
import { assert } from '@ember/debug';

export type OAuthProviders = 'github' | 'google' | 'facebook' | 'linkedin' | 'gitlab';

type RemoteUser = {
  email: string;
  id: string;
};

export default class AuthService extends Service {
  @service declare supabaseProxy: Registry['supabase-proxy'];
  @service declare store: Registry['store'];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  supabase: any;

  @tracked token?: string;
  @tracked remoteUser?: RemoteUser;
  @tracked localUser?: UserModel;

  constructor(owner: EmberOwner) {
    super(owner);
    this.supabase = this.supabaseProxy.createClient();
  }

  get isAuthenticated(): boolean {
    return Boolean(this.remoteUser);
  }

  async checkLogin(): Promise<void> {
    const { data, error } = await this.supabase.auth.getSession();

    if (error) {
      throw error;
    }

    if (data.session) {
      const {
        data: { user },
      } = await this.supabase.auth.getUser();

      await this.setTokenAndFetchUser(user, data.session.access_token);
    }

    return;
  }

  async login(email?: string, password?: string): Promise<void> {
    const { data, error } = await this.supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      throw error;
    }

    await this.setTokenAndFetchUser(data.user, data.session.access_token);
    return;
  }

  async removeAccount(): Promise<void> {
    await this.localUser?.destroyRecord();
    this.removeUserAndToken();
  }

  async signInWithOAuth(provider: string): Promise<void> {
    const { data, error } = await this.supabase.auth.signInWithOAuth({
      provider,
    });

    if (error) {
      throw error;
    }

    await this.setTokenAndFetchUser(data.user, data.session.access_token);
    return;
  }

  async logout(): Promise<void> {
    const { error } = await this.supabase.auth.signOut();
    if (error) {
      throw error;
    }

    this.removeUserAndToken();
    return;
  }

  async requestPasswordReset(email?: string): Promise<void> {
    const { error } = await this.supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${config.frontHost}/logowanie/resetowanie-hasla`,
    });

    if (error) {
      throw error;
    }
  }

  async updateEmailOrPassword(email?: string, password?: string) {
    assert('You must provide either email or password', email || password);

    const { error } = await this.supabase.auth.updateUser({
      email,
      password,
    });

    if (error) {
      throw error;
    }
  }

  async updateUser({ email, password }: { email?: string; password?: string }) {
    const { data, error } = await this.supabase.auth.updateUser({
      email,
      password,
    });

    if (error) {
      throw error;
    }

    await this.setTokenAndFetchUser(data.user, data.session.access_token);
  }

  async signup(email?: string, password?: string): Promise<void> {
    const { error } = await this.supabase.auth.signUp({
      email,
      password,
    });

    if (error) {
      throw error;
    }
  }

  private removeUserAndToken(): void {
    if (this.remoteUser?.id) {
      (this.store.peekRecord('user', this.remoteUser.id) as UserModel)?.unloadRecord();
    }

    this.remoteUser = undefined;
    this.token = undefined;
  }

  private async setTokenAndFetchUser(user: RemoteUser, accessToken: string): Promise<void> {
    this.token = accessToken;
    this.remoteUser = user;

    try {
      this.localUser = await this.store.findRecord('user', user.id, {
        include: 'subscription',
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.errors?.length && e.errors[0].status === '404') {
        throw 'USER_NOT_FOUND';
      }

      throw e;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    auth: AuthService;
  }
}
