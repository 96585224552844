import { action } from '@ember/object';
import Component from '@glimmer/component';
// @ts-expect-error ember-local-storage-decorator is not yet typed
import localStorage from 'ember-local-storage-decorator';

interface CookiesConsentSignature {
  Element: HTMLDivElement;
  Args: object;
}

export default class CookiesConsent extends Component<CookiesConsentSignature> {
  @localStorage showCookieConsent = true;

  @action
  acceptCookies(): void {
    this.showCookieConsent = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CookiesConsent: typeof CookiesConsent;
  }
}
