import Component from '@glimmer/component';
import { add, isAfter, isBefore, isSameMonth } from 'date-fns';

export interface CalendarNavSignature {
  Element: HTMLElement;
  Args: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    calendar: any; // PowerCalendar;
  };
}

export default class CalendarNav extends Component<CalendarNavSignature> {
  get currentMonth() {
    return this.args.calendar.center.toLocaleDateString('pl-PL', {
      year: 'numeric',
      month: 'long',
    });
  }

  get shouldShowLeftArrow() {
    if (isSameMonth(this.args.calendar.center, Date.now())) {
      return false;
    } else if (isAfter(this.args.calendar.center, Date.now())) {
      return true;
    } else {
      return false;
    }
  }

  get showShowRightArrow() {
    if (isBefore(this.args.calendar.center, add(Date.now(), { months: 2 }))) {
      return true;
    } else {
      return false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calendar::Nav': typeof CalendarNav;
    'calendar/nav': typeof CalendarNav;
  }
}
