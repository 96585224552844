import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import UserModel from '4k-web/models/user';
import DeskBookingModel from '4k-web/models/desk-booking';
import { differenceInDays } from 'date-fns';
import type { IconName } from '@fortawesome/fontawesome-svg-core';

export type SubscriptionType = '4k' | 'hd' | 'sd';

export default class SubscriptionModel extends Model {
  @attr('short-date-to-date') declare dateFrom: Date;
  @attr('short-date-to-date') declare dateTo: Date;
  @attr('number') declare credits: number;

  @belongsTo('user', { async: false, inverse: 'subscription' }) declare user: UserModel;
  @hasMany('desk-booking', { async: false, inverse: 'subscription' })
  declare deskBookings: DeskBookingModel[];

  get dateToFormatted(): string {
    return this.dateTo.toLocaleDateString('pl-PL', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  get daysToEnd(): number {
    return differenceInDays(this.dateTo, new Date());
  }

  get remainingCreditsIcon(): IconName {
    if (this.credits >= 15) {
      return 'battery-full';
    } else if (this.credits >= 10) {
      return 'battery-three-quarters';
    } else if (this.credits >= 5) {
      return 'battery-half';
    } else if (this.credits >= 2) {
      return 'battery-quarter';
    } else {
      return 'battery-slash';
    }
  }

  get remainingDaysIcon(): IconName {
    if (this.daysToEnd >= 7) {
      return 'heart-circle-check';
    } else if (this.daysToEnd >= 3) {
      return 'heart-circle-exclamation';
    } else {
      return 'heart-crack';
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    subscription: SubscriptionModel;
  }
}
