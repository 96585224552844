import ArrayProxy from '@ember/array/proxy';
import { Registry, inject as service } from '@ember/service';
import Component from '@glimmer/component';
import PaymentModel from '4k-web/models/payment';
import { EmberOwner } from 'utils';
import { task } from 'ember-concurrency';

interface PaymentsTableSignature {
  Element: HTMLDivElement;
  Args: object;
}

export default class PaymentsTable extends Component<PaymentsTableSignature> {
  @service declare store: Registry['store'];

  constructor(owner: EmberOwner, args: PaymentsTableSignature['Args']) {
    super(owner, args);
    this.loadPaymentsTask.perform();
  }

  get payments(): ArrayProxy<PaymentModel> | null | undefined {
    return this.loadPaymentsTask.last?.value;
  }

  get headers() {
    return ['ID', 'Kwota', 'Data', 'Status', 'Faktura'];
  }

  private loadPaymentsTask = task(async () => {
    const payments = await this.store.findAll('payment');
    return payments;
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PaymentsTable: typeof PaymentsTable;
  }
}
