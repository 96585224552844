import Component from '@glimmer/component';
import type { IconName } from '@fortawesome/fontawesome-svg-core';

export interface UiButtonSignature {
  Element: HTMLButtonElement;
  Args: {
    busyText?: string;
    disabled?: boolean;
    icon?: IconName;
    isBlock?: boolean;
    isBusy?: boolean;
    size?: 'lg' | 'regular' | 'sm';
    variant?: 'primary' | 'outline' | 'text' | 'danger' | 'provider';
  };
  Blocks: {
    default: [];
  };
}

export default class UiButton extends Component<UiButtonSignature> {
  get isDisabled(): boolean {
    return Boolean(this.args.disabled || this.args.isBusy);
  }

  get size(): string {
    return this.args.size ?? 'regular';
  }

  get variant(): string {
    return this.args.variant ?? 'primary';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Button': typeof UiButton;
  }
}
