import Service, { Registry, inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class Subscription extends Service {
  @service declare store: Registry['store'];

  fetchSubscriptionTask = task(async () => {
    return this.store.queryRecord('subscription', { include: 'user' });
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    subscription: Subscription;
  }
}
