import { IconName } from '@fortawesome/fontawesome-svg-core';

export type SubscriptionDefinition = {
  type: '4k' | 'hd' | 'sd';
  price: number;
  days: number;
  icon: IconName;
  features?: string[];
  expiration: number;
};

const availableSubscriptions: SubscriptionDefinition[] = [
  {
    type: 'sd',
    price: 70,
    days: 1,
    expiration: 3,
    icon: 'computer-classic',
    features: ['Sala konferencyjna w cenie'],
  },
  {
    type: 'hd',
    price: 50,
    icon: 'tv-retro',
    expiration: 15,
    days: 10,
  },
  {
    type: '4k',
    price: 40,
    icon: 'tv',
    expiration: 40,
    days: 20,
  },
];

export default availableSubscriptions;
