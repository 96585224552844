import Transform from '@ember-data/serializer/transform';
import { format } from 'date-fns';

export default class ShortDateToDate extends Transform {
  deserialize(serialized: string): Date {
    return new Date(serialized);
  }

  serialize(deserialized: Date): string {
    return format(deserialized, 'yyyy-MM-dd');
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'short-date-to-date': ShortDateToDate;
  }
}
