import { createClient } from '@supabase/supabase-js';
import config from '4k-web/config/environment';
import Service from '@ember/service';

export default class SupabaseProxyService extends Service {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: any;

  createClient() {
    return createClient(config.supabase.url, config.supabase.key);
  }
}

declare module '@ember/service' {
  interface Registry {
    'supabase-proxy': SupabaseProxyService;
  }
}
