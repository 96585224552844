import Inflector from 'ember-inflector';

export function initialize(): void {
  const { inflector } = Inflector;

  inflector.irregular('dzień', 'dni');
}

export default {
  name: 'custom-inflector-rules',
  initialize,
};
