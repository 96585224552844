import { helper as buildHelper } from '@ember/component/helper';

export function formatDate([date]: [Date]): string {
  return date.toLocaleDateString('pl-PL', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export default buildHelper(formatDate);
