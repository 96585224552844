import { inject as service, Registry } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import subscriptions from '4k-web/dictionaries/subscriptions';
import SubscriptionModel, { SubscriptionType } from '4k-web/models/subscription';

export interface SubscriptionsSignature {
  Element: HTMLDivElement;
  Args: object;
}

export default class Subscriptions extends Component<SubscriptionsSignature> {
  @service declare auth: Registry['auth'];

  subscriptions = subscriptions;
  @tracked radioValue: SubscriptionType = 'hd';

  get activeSubscription() {
    return this.auth.localUser?.subscription as SubscriptionModel;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Subscriptions: typeof Subscriptions;
  }
}
