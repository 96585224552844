import Model, { attr } from '@ember-data/model';

export type ConferenceRoomBookingType = 'conference_room';

export default class UnavailableDatesModel extends Model {
  @attr() declare dates: Date;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'unavailable-date': UnavailableDatesModel;
  }
}
