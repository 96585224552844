import Model, { attr, belongsTo } from '@ember-data/model';
import UserModel from '4k-web/models/user';

export type ConferenceRoomBookingType = 'conference_room';

export default class ConferenceRoomBookingModel extends Model {
  @attr('iso-to-date') declare endDate: Date;
  @attr('iso-to-date') declare startDate: Date;
  @attr declare status: 'confirmed' | 'cancelled';

  @belongsTo('user', { async: false, inverse: 'conferenceRoomBookings' }) declare user: UserModel;

  get isCancelled(): boolean {
    return this.status === 'cancelled';
  }

  get isConfirmed(): boolean {
    return this.status === 'confirmed';
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'conference-room-booking': ConferenceRoomBookingModel;
  }
}
