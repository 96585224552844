import { Registry, inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export interface LoginEmailSignature {
  Element: HTMLFormElement;
  Args: object;
}

export default class LoginEmail extends Component<LoginEmailSignature> {
  @service declare auth: Registry['auth'];
  @service declare flashMessages: Registry['flash-messages'];
  @service declare router: Registry['router'];

  @tracked email = '';
  @tracked password = '';
  @tracked signupCompleted = false;

  get isLoginDisabled(): boolean {
    return !this.email || !this.password || this.loginTask.isRunning;
  }

  loginTask = task(async (e: Event) => {
    e.preventDefault();

    try {
      await this.auth.login(this.email, this.password);
      this.flashMessages.success('🎉 Logowanie przebiegło poprawnie!');
      this.router.transitionTo('bookings');
    } catch (e) {
      this.flashMessages.danger('🔒 Niepoprawne dane logowania.');
    }
  });

  requestPasswordResetTask = task(async () => {
    try {
      await this.auth.requestPasswordReset(this.email);
      this.flashMessages.success('📧 Wysłaliśmy Ci wiadomość z linkiem do resetu hasła.');
    } catch (e) {
      this.flashMessages.danger(
        '🔒 Wystąpił problem z resetem hasła. Prosimy spróbować ponownie później.'
      );
    }
  });

  signupTask = task(async (e: Event) => {
    e.preventDefault();

    try {
      await this.auth.signup(this.email, this.password);
      this.flashMessages.success('🎉 Rejestracja przebiegła pomyślnie!');
      this.signupCompleted = true;
    } catch (e) {
      this.flashMessages.danger(
        '🔒 Wystąpił problem z rejestracją. Prosimy spróbować ponownie później.'
      );
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Login::Email': typeof LoginEmail;
  }
}
