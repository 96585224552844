import templateOnlyComponent from '@ember/component/template-only';
import { DeskBookingType } from '4k-web/models/desk-booking';
import { ConferenceRoomBookingType } from '4k-web/models/conference-room-booking';

interface ProductTypeRadioSignature {
  Element: HTMLFormElement;
  Args: {
    disabled?: boolean;
    icon: 'chair-office' | 'presentation';
    label: string;
    itemValue: DeskBookingType | ConferenceRoomBookingType;
    productType: DeskBookingType | ConferenceRoomBookingType;
    updateProductType: () => void;
  };
}

const ProductTypeRadio = templateOnlyComponent<ProductTypeRadioSignature>();

export default ProductTypeRadio;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Bookings::ProductTypeRadio': typeof ProductTypeRadio;
  }
}
