import templateOnlyComponent from '@ember/component/template-only';

interface UiSeparatorSignature {
  Element: HTMLDivElement;
  Args: { size?: 'sm' };
}

const UiSeparator = templateOnlyComponent<UiSeparatorSignature>();

export default UiSeparator;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Ui::Separator': typeof UiSeparator;
  }
}
