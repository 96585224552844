import Component from '@glimmer/component';
import { Registry, inject as service } from '@ember/service';
import { TrackedObject } from 'tracked-built-ins';

import { task } from 'ember-concurrency';
import { EmberOwner } from 'utils';

interface ContactFormModel {
  fullName: string;
  phone: string;
  email: string;
  message: string;
  isAgreed: boolean;
}

export interface ContactFormSignature {
  Element: HTMLFormElement;
  Args: object;
}

export default class ContactForm extends Component<ContactFormSignature> {
  @service declare auth: Registry['auth'];
  @service declare flashMessages: Registry['flash-messages'];

  model: ContactFormModel;

  constructor(owner: EmberOwner, args: ContactFormSignature['Args']) {
    super(owner, args);

    this.model = new TrackedObject({
      fullName: '',
      phone: '',
      email: '',
      message: '',
      isAgreed: false,
    });

    if (this.auth.localUser) {
      this.model.fullName = `${this.auth.localUser.firstName} ${this.auth.localUser.lastName}`;
      this.model.email = this.auth.localUser.email;
      this.model.phone = this.auth.localUser.phone;
    }
  }

  get isSendDisabled() {
    return !this.model.isAgreed || !this.model.message || !this.model.email;
  }

  submitFormTask = task(async (e: Event) => {
    e.preventDefault();

    try {
      await fetch('https://formspree.io/f/mnqybeqb', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.model),
      });
      this.flashMessages.success('🎉 Wiadomość została wysłana. Dziękujemy!');
    } catch (e) {
      this.flashMessages.danger('😞 Nie udało się wysłać wiadomości. Spróbuj ponownie później.');
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ContactForm: typeof ContactForm;
  }
}
