import { inject as service } from '@ember/service';
import type { Registry } from '@ember/service';
import { format } from 'date-fns';
import ApplicationAdapter from './application';

export default class DeskBookingAdapter extends ApplicationAdapter {
  @service declare store: Registry['store'];

  async bulkCreate(dates: Date[]) {
    const url = this.buildURL('desk-booking');
    const shortDates = dates.map((date) => format(date, 'dd-MM-yyyy'));

    const response = await fetch(`${url}?include=subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.auth.token}`,
      },
      body: JSON.stringify({
        dates: shortDates,
      }),
    });

    const responseJSON = await response.json();

    if (!response.ok) {
      throw new Error(responseJSON.errors[0].title);
    }

    this.store.pushPayload(responseJSON);
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'desk-booking': DeskBookingAdapter;
  }
}
