import {
  validateLength,
  validateConfirmation,
  validatePresence,
  // @ts-expect-error seems like something is wrong with detection of the exported members
} from 'ember-changeset-validations/validators';

export default {
  password: [validatePresence({ presence: true, ignoreBlank: true }), validateLength({ min: 8 })],
  passwordConfirmation: [
    validatePresence({ presence: true, ignoreBlank: true }),
    validateConfirmation({ on: 'password' }),
  ],
};
