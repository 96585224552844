import { action } from '@ember/object';
import { ConferenceRoomBookingType } from '4k-web/models/conference-room-booking';
import { inject as service, Registry } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import DeskBookingModel, { DeskBookingType } from '4k-web/models/desk-booking';
import SubscriptionModel from '4k-web/models/subscription';
import { EmberOwner } from 'utils';
import { format } from 'date-fns';

export interface BookingsListSignature {
  Element: HTMLDivElement;
  Args: object;
}

export default class BookingsList extends Component<BookingsListSignature> {
  @service declare auth: Registry['auth'];
  @service declare flashMessages: Registry['flash-messages'];
  @service declare store: Registry['store'];
  @service declare subscription: Registry['subscription'];

  @tracked center: Date = new Date();
  @tracked productType: DeskBookingType | ConferenceRoomBookingType = 'desk';
  @tracked selectedDates: Date[] = [];

  constructor(owner: EmberOwner, args: BookingsListSignature['Args']) {
    super(owner, args);
    this.fetchBookingsTask.perform();
    this.fetchUnavailableDatesTask.perform();
  }

  get bookings() {
    return this.fetchBookingsTask.last?.value || [];
  }

  get unavailableDates() {
    return this.fetchUnavailableDatesTask.last?.value?.dates || [];
  }

  fetchBookingsTask = task(async () => {
    const bookings = await this.store.findAll('desk-booking', { include: 'subscription' });
    return bookings;
  });

  fetchUnavailableDatesTask = task(async () => {
    return this.store.queryRecord('unavailable-date', {
      start_date: format(this.minDate, 'yyyy-MM-dd'),
      end_date: format(this.maxDate, 'yyyy-MM-dd'),
    });
  });

  get bookedDates(): Date[] {
    return this.bookings
      .filter((booking) => booking.isConfirmed)
      .map((booking) => booking.date)
      .sort((a, b) => b.getTime() - a.getTime());
  }

  get currentSubscription(): SubscriptionModel {
    return this.auth.localUser?.subscription as SubscriptionModel;
  }

  get maxDate(): Date {
    const subscription = this.auth.localUser?.subscription;
    return subscription?.dateTo || new Date();
  }

  get minDate(): Date {
    return new Date();
  }

  @action
  changeCenter({ date }: { date: Date }) {
    this.center = date;
  }

  @action
  async selectDate({ date }: { date: Date[] }): Promise<void> {
    this.selectedDates = date;
  }

  cancelBookingTask = task(async (day) => {
    const dayToCancel = this.bookings.find(
      (booking) => booking.dateShortFormatted === day.id
    ) as DeskBookingModel;

    dayToCancel.status = 'cancelled';

    try {
      await dayToCancel.save({ adapterOptions: { query: { include: 'subscription' } } });
      this.flashMessages.success('🎉 Rezerwacja anulowana');
    } catch (e) {
      this.flashMessages.danger('💥 Nie udało się anulować rezerwacji');
    }
  });

  createBookingTask = task(async () => {
    try {
      await this.store.adapterFor('desk-booking').bulkCreate(this.selectedDates);
      this.flashMessages.success('🎉 Rezerwacja potwierdzona');
      this.selectedDates = [];
    } catch (e: any) {
      this.flashMessages.danger(`😞 ${e.message}`);
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Bookings::List': typeof BookingsList;
  }
}
