import Model, { attr, belongsTo } from '@ember-data/model';
import UserModel from '4k-web/models/user';
import { format } from 'date-fns';
import type { IconName } from '@fortawesome/fontawesome-svg-core';
import { SubscriptionType } from './subscription';

export default class PaymentModel extends Model {
  @attr('iso-to-date') declare createdAt: Date;
  @attr('string') declare paymentMethod: 'pbl' | 'card' | 'blik' | 'cash' | 'ing';
  @attr('string') declare subscriptionType: SubscriptionType;
  @attr('string') declare status:
    | 'new'
    | 'pending'
    | 'rejected'
    | 'settled'
    | 'error'
    | 'cancelled';
  @attr('number') declare amount: number; // in 1/100 PLN
  // @attr('string') declare invoiceId: string;
  @attr('string') declare paymentLink: string;

  @belongsTo('user', { async: false, inverse: 'payment' }) declare user: UserModel;

  get dateShortFormatted(): string {
    return format(this.createdAt, 'yyyy-MM-dd');
  }

  get dateWithTimeFormatted(): string {
    return format(this.createdAt, 'yyyy-MM-dd HH:mm');
  }

  get friendlyStatus(): string {
    switch (this.status) {
      case 'settled':
        return 'Zapłacono';
      case 'pending':
        return 'Oczekuje';
      case 'rejected':
        return 'Płatność nie powiodła się';
      case 'error':
        return 'Płatność nie powiodła się';
      default:
        return 'Nieznany';
    }
  }

  get friendlyMethod(): string {
    switch (this.paymentMethod) {
      case 'card':
        return 'karta';
      case 'cash':
        return 'gotówka';
      case 'pbl':
        return 'płatność online';
      case 'blik':
        return 'BLIK';
      case 'ing':
        return 'Płacę z ING';
      default:
        return 'nieznana';
    }
  }

  get methodIcon(): IconName {
    switch (this.paymentMethod) {
      case 'card':
        return 'credit-card';
      case 'cash':
        return 'coins';
      case 'pbl':
        return 'money-bill-transfer';
      case 'blik':
        return 'money-bill-transfer';
      case 'ing':
        return 'money-bill-transfer';
      default:
        return 'question';
    }
  }

  get statusIcon(): IconName {
    switch (this.status) {
      case 'settled':
        return 'check-circle';
      case 'pending':
        return 'clock';
      case 'rejected':
        return 'times-circle';
      default:
        return 'question';
    }
  }

  get friendlyAmount(): string {
    return `${this.amount / 100} zł`;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    payment: PaymentModel;
  }
}
