import { BufferedChangeset } from 'validated-changeset';
import { EmberOwner, Maybe } from 'utils';
import { inject as service, Registry } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import ContactDataValidations from '4k-web/validations/contact-data';
import Component from '@glimmer/component';
import PasswordValidations from '4k-web/validations/password';
import UserDataValidations from '4k-web/validations/user-data';

type Password = {
  password?: string;
  passwordConfirmation?: string;
};

interface ProfileSignature {
  Args: object;
}

export default class Profile extends Component<ProfileSignature> {
  @service declare auth: Registry['auth'];
  @service declare flashMessages: Registry['flash-messages'];
  @service declare router: Registry['router'];

  UserDataValidations = UserDataValidations;
  PasswordValidations = PasswordValidations;
  ContactDataValidations = ContactDataValidations;

  @tracked email: string;
  @tracked passwords: Password;

  constructor(owner: EmberOwner, args: ProfileSignature['Args']) {
    super(owner, args);

    this.passwords = {
      password: '',
      passwordConfirmation: '',
    };

    this.email = this.auth.remoteUser?.email as string;
  }

  removeAccountTask = task(async (e: Event) => {
    e.preventDefault();
    try {
      await this.auth.removeAccount();
      this.flashMessages.success('Twoje konto zostało poprawnie usunięte.');
      this.router.transitionTo('index');
    } catch {
      this.flashMessages.danger('Wystąpił błąd podczas usuwania konta.');
    }
  });

  updateContactDataTask = task(async (changeset: BufferedChangeset, e: Event) => {
    e.preventDefault();
    await changeset.save();
  });

  updateEmailOrPassword = task(async (changeset: BufferedChangeset, e: Event) => {
    e.preventDefault();
    await this.auth.updateEmailOrPassword(
      changeset['email'] as Maybe<string>,
      changeset['password'] as Maybe<string>
    );
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Profile: typeof Profile;
  }
}
