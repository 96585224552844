import Route from '@ember/routing/route';
import { inject as service, Registry } from '@ember/service';

export default class Application extends Route {
  @service declare auth: Registry['auth'];
  @service declare flashMessages: Registry['flash-messages'];
  @service declare powerCalendar: Registry['power-calendar'];
  @service declare router: Registry['router'];

  async beforeModel(): Promise<void> {
    // @ts-expect-error PowerCalendar is not typed properly
    this.powerCalendar.locale = 'pl';

    try {
      await this.auth.checkLogin();
      if (this.auth.isAuthenticated && !this.auth.localUser?.isOnboarded) {
        this.router.transitionTo('onboarding');
        return;
      }
    } catch (e) {
      await this.auth.logout();
      this.flashMessages.warning('🔒 Twoja sesja została zakończona, zaloguj się ponownie.');
      this.router.transitionTo('login');
    }
  }
}
