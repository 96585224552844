import { TaskForAsyncTaskFunction } from 'ember-concurrency';
import templateOnlyComponent from '@ember/component/template-only';
import type { BufferedChangeset } from 'validated-changeset';

interface ProfileUserDataFormSignature {
  Element: HTMLFormElement;
  Args: {
    changeset: BufferedChangeset;
    onSubmit: TaskForAsyncTaskFunction<
      unknown,
      (changeset: BufferedChangeset, e: Event) => Promise<void>
    >;
  };
}

const ProfileUserDataForm = templateOnlyComponent<ProfileUserDataFormSignature>();

export default ProfileUserDataForm;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Profile::UserDataForm': typeof ProfileUserDataForm;
  }
}
