import Transform from '@ember-data/serializer/transform';
import { Maybe } from 'utils';

export default class IsoToDate extends Transform {
  deserialize(serialized: string): Maybe<Date> {
    if (!serialized) return;
    return new Date(serialized);
  }

  serialize(deserialized: Date): Maybe<string> {
    if (!deserialized) return;
    return deserialized.toISOString();
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'iso-to-date': IsoToDate;
  }
}
