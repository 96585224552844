import { helper } from '@ember/component/helper';
import { pluralize } from 'ember-inflector';

export interface MakePluralHelperPositionalSignature {
  Args: {
    Positional: [number, string, string];
    Named: object;
  };
  Return: string;
}

const makePlural = helper<MakePluralHelperPositionalSignature>((positional) => {
  const [num, singular, plural] = positional;
  const isPlural = num !== 1;

  if (isPlural) {
    return plural || pluralize(singular);
  }

  return singular;
});

export default makePlural;
