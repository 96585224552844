import templateOnlyComponent from '@ember/component/template-only';

interface BasicBoxSignature {
  Element: HTMLDivElement;
  Args: object;
  Blocks: {
    default: [];
  };
}

const BasicBox = templateOnlyComponent<BasicBoxSignature>();

export default BasicBox;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    BasicBox: typeof BasicBox;
  }
}
