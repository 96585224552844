import EmberRouter from '@embroider/router';
import config from '4k-web/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('profile', { path: '/moj-profil' });
  this.route('faq');
  this.route('payments', { path: '/platnosci' }, function () {
    this.route('index', { path: '/' });
    this.route('payment-success', { path: '/sukces' });
    this.route('payment-failure', { path: '/platnosc-nieudana' });
  });
  this.route('contact', { path: '/kontakt' });
  this.route('callback');
  this.route('login', { path: '/logowanie' }, function () {
    this.route('index', { path: '/' });
    this.route('forgot-password', { path: '/resetowanie-hasla' });
  });
  this.route('logout', { path: '/wyloguj' });
  this.route('privacy', { path: '/polityka-prywatnosci' });
  this.route('terms', { path: '/regulamin' });
  this.route('bookings', { path: '/moje-rezerwacje' });
  this.route('map', { path: '/gdzie-jestesmy' });
  this.route('subscriptions', { path: '/moj-pakiet' }, function () {
    this.route('index', { path: '/' });
  });
  this.route('onboarding', { path: '/czesc' });

  this.route('not-found', { path: '/*path' });
});
