import Route from '@ember/routing/route';
import { inject as service, Registry } from '@ember/service';

export default class Restricted extends Route {
  @service declare auth: Registry['auth'];
  @service declare maintenance: Registry['maintenance'];
  @service declare router: Registry['router'];

  async beforeModel(): Promise<void> {
    if (!this.auth.isAuthenticated) {
      this.router.transitionTo('login');
      return;
    }

    if (!this.auth.localUser?.isOnboarded) {
      this.router.transitionTo('onboarding');
      return;
    }
  }
}
