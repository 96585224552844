import Component from '@glimmer/component';
import { inject as service, Registry } from '@ember/service';
import SubscriptionModel from '4k-web/models/subscription';
import { EmberOwner } from 'utils';

export interface SubscriptionInfoSignature {
  Element: HTMLDivElement;
  Args: {
    withoutBorder?: boolean;
  };
}

export default class SubscriptionInfo extends Component<SubscriptionInfoSignature> {
  @service declare auth: Registry['auth'];
  @service declare router: Registry['router'];
  @service declare store: Registry['store'];
  @service declare subscription: Registry['subscription'];

  constructor(owner: EmberOwner, args: SubscriptionInfoSignature['Args']) {
    super(owner, args);
    this.subscription.fetchSubscriptionTask.perform();
  }

  get activeSubscription() {
    return this.auth.localUser?.subscription as SubscriptionModel;
  }

  get daysIconColor() {
    if (this.activeSubscription?.daysToEnd >= 7) {
      return 'text-lime-500';
    } else if (this.activeSubscription?.daysToEnd >= 3) {
      return 'text-orange-500';
    } else {
      return 'text-red-500';
    }
  }

  get creditsIconColor() {
    if (this.activeSubscription?.credits >= 10) {
      return 'text-lime-500';
    } else if (this.activeSubscription?.credits >= 5) {
      return 'text-orange-500';
    } else if (this.activeSubscription?.credits >= 2) {
      return 'text-red-500';
    } else {
      return 'text-red-500';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SubscriptionInfo: typeof SubscriptionInfo;
  }
}
