import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { EmberOwner } from 'utils';

export const MAGIC_WORD = '4kdevteam';

export default class Maintenance extends Service {
  _boundMagicWordHandler = this.magicWordHandler.bind(this);

  @tracked isEnabled = false;
  @tracked private _magicWordEntered = '';

  constructor(owner: EmberOwner) {
    super(owner);
    document.addEventListener('keydown', this._boundMagicWordHandler);
  }

  private magicWordHandler(e: KeyboardEvent): void {
    // allow resetting the magic string by entering "!" and prevent variable value to be concated infinitely
    const magicWordMaxLength = Math.max(MAGIC_WORD.length);

    if (e.key === '!' || e.key === 'Shift' || this._magicWordEntered.length > magicWordMaxLength) {
      this._magicWordEntered = '';
      return;
    }

    this._magicWordEntered += e.key;

    if (this._magicWordEntered === MAGIC_WORD) {
      this.isEnabled = !this.isEnabled;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    maintenance: Maintenance;
  }
}
