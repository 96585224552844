import Component from '@glimmer/component';
import { inject as service, Registry } from '@ember/service';
import { action } from '@ember/object';

interface MainMenuSignature {
  Element: HTMLElement;
  Args: {
    responsiveMenu?: boolean;
  };
}

export default class MainMenu extends Component<MainMenuSignature> {
  @service declare auth: Registry['auth'];
  @service declare maintenance: Registry['maintenance'];
  @service declare router: Registry['router'];

  @action
  logout() {
    this.auth.logout();
    this.router.transitionTo('index');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    MainMenu: typeof MainMenu;
  }
}
