import Controller from '@ember/controller';
import { action } from '@ember/object';
import { Registry, inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ApplicationController extends Controller {
  @service declare auth: Registry['auth'];
  @service declare flashMessages: Registry['flash-messages'];
  @service declare maintenance: Registry['maintenance'];
  @service declare router: Registry['router'];

  @tracked shouldShowMenu = false;

  get isLoginRouteActive(): boolean {
    return this.router.currentRouteName.includes('login');
  }

  get isMapRouteActive(): boolean {
    return this.router.currentRouteName === 'map';
  }

  @action
  async logout() {
    await this.auth.logout();
    this.router.transitionTo('login');
  }
}
