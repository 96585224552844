import { SubscriptionDefinition } from '4k-web/dictionaries/subscriptions';
import templateOnlyComponent from '@ember/component/template-only';

export interface SubscriptionBoxSimpleSignature {
  Element: HTMLDivElement;
  Args: {
    subscription: SubscriptionDefinition;
  };
}

const SubscriptionBoxSimple = templateOnlyComponent<SubscriptionBoxSimpleSignature>();
export default SubscriptionBoxSimple;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SubscriptionBoxSimple: typeof SubscriptionBoxSimple;
  }
}
