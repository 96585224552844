import { SubscriptionDefinition } from '4k-web/dictionaries/subscriptions';
import Component from '@glimmer/component';
import { Registry, inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export interface SubscriptionBoxSignature {
  Element: HTMLDivElement;
  Args: {
    subscription: SubscriptionDefinition;
    radioValue: '4k' | 'hd' | 'sd';
    selectSubscription: () => void;
  };
  Blocks: {
    features: [];
  };
}

export default class SubscriptionBox extends Component<SubscriptionBoxSignature> {
  @service declare flashMessages: Registry['flash-messages'];
  @service declare store: Registry['store'];
  @service declare payments: Registry['payments'];
  @service declare router: Registry['router'];

  buySubscriptionTask = task(async () => {
    try {
      await this.payments.buySubscription(this.args.subscription.type);
      return;
    } catch (e) {
      this.flashMessages.danger('🤔 Coś poszło nie tak. Spróbuj ponownie później.');
      this.router.transitionTo('payments.payment-failure');
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SubscriptionBox: typeof SubscriptionBox;
  }
}
