import Model, { attr, belongsTo } from '@ember-data/model';
import SubscriptionModel from '4k-web/models/subscription';
import UserModel from '4k-web/models/user';
import { format } from 'date-fns';

export type DeskBookingType = 'desk';

export default class DeskBookingModel extends Model {
  @attr('short-date-to-date') declare date: Date;
  @attr() declare status: 'booked' | 'cancelled';

  @belongsTo('user', { async: false, inverse: 'deskBookings' }) declare user: UserModel;
  @belongsTo('subscription', { async: false, inverse: 'deskBookings' })
  declare subscription: SubscriptionModel;

  get isCancelled(): boolean {
    return this.status === 'cancelled';
  }

  get isConfirmed(): boolean {
    return this.status === 'booked';
  }

  get dateFormatted(): string {
    return this.date.toLocaleDateString('pl-PL', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  get dateShortFormatted(): string {
    return format(this.date, 'yyyy-MM-dd');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'desk-booking': DeskBookingModel;
  }
}
