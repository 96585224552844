import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type DeskBookingModel from '4k-web/models/desk-booking';
import type ConferenceRoomBookingModel from '4k-web/models/conference-room-booking';
import SubscriptionModel from '4k-web/models/subscription';
import PaymentModel from '4k-web/models/payment';

export default class UserModel extends Model {
  @attr('string') declare email: string;
  @attr('string') declare firstName: string;
  @attr('string') declare lastName: string;
  @attr('string') declare phone: string;
  @attr('string') declare taxId: string;
  @attr('string') declare role: 'customer' | 'admin';

  @belongsTo('payment', { async: false, inverse: 'user' }) declare payment: PaymentModel;

  @hasMany('conference-room-booking', { async: false, inverse: 'user' })
  declare conferenceRoomBookings: ConferenceRoomBookingModel[];

  @hasMany('desk-booking', { async: false, inverse: 'user' })
  declare deskBookings: DeskBookingModel[];

  @belongsTo('subscription', { async: false, inverse: 'user' })
  declare subscription: SubscriptionModel;

  get isOnboarded(): boolean {
    return Boolean(this.firstName && this.lastName);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    user: UserModel;
  }
}
